export default {
  common: {
    colon: '：',
    close: '关闭',
    jpy: 'JPY',
    error: '错误',
    contact: '洽询',
    delete: '删除'
  },
  email: {
    subject: '关于（{{orderId}}）订单',
    orderId: '订单号码: {{orderId}}',
    contact: '负责人您好，\n<请于下方输入洽询内容。 >'
  },
  account: {
    title: '付款方式管理',
    caption: '已登录的信用卡',
    tips: '※最多可登录5张信用卡。',
    deleteCardSuccess: '已删除此信用卡。'
  },
  order: {
    bookingId: '订单号码',
    totalPrice: '总金额',
    useAvailableCard: '使用已登录的信用卡',
    useNewCard: '使用新的信用卡',
    acceptedPayment: '可使用的付款方式',
    email: '电子邮箱',
    deadline: '付款期限',
    pay: '{{amount}} 支付',
    error: '购买程序发生错误！错误代码：{{code}}',
    secure: '3D Secure',
    secureMessage:
      '如欲使用本公司的服务，必须使用<a>本人验证服务「3D Secure 2.0（EMV 3D Secure）」</a> 。',
    saveCardInfo: '储存信用卡信息',
    creditCardVerified: '已完成信用卡确认。',
    incompleteOrder: '订单尚未完成。',
    clickToConfirm: '请务必点选下方确认钮。',
    confirmOrder: '确认订单',
    countdown: '（ {{count}} 秒）',
    paymentError: '付款程序发生错误。',
    confirmDeadline: '请确认订单资讯是否正确及有效期限。',
    orderSuccess: '已确认到此订单。 \n 感谢您的使用。',
    contact: '若问题仍未解决、\n 请点选「洽询」选项洽询。',
    backShop: '返回商品页面',
    paymentDetails: '付款详细',
    paymentLogs: '付款履历',
    print: '打印目前页面'
  },
  product: {
    name: '种类',
    price: '单价',
    quantity: '数量',
    total: '金额'
  },
  transaction: {
    timestamp: '时间',
    status: '状态',
    message: '详细信息',
    charge: '付款'
  },
  card: {
    cardNumber: '卡号',
    cardExpiry: '有效期限',
    cardCvc: '安全码',
    cardName: '信用卡上的名称'
  },
  footer: {
    terms: '利用条约',
    privacy: '隐私权政策',
    faq: '常见疑问'
  },
  error: {
    invalidCardNumber: '此卡号无效。',
    invalidCardType: '此卡型无效。',
    invalidCardExpiry: '信用卡有效期限无效。',
    pastCardExpiry: '信用卡已过有效期限。',
    invalidCardCvc: '信用卡验证码无效。',
    invalidCardName: '请以半形字母输入姓名。'
  }
};
