import { Gmoshop } from '@linktivity/link-connect';
import { message } from '@/components';
import { BASE_PATH } from '@/constants/env';

const config = new Gmoshop.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      post: async context => {
        if (context.response.status === 404) {
          location.href = '/404';
        } else if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data.common.error_description || data.common.error_code,
            10
          );
        }
      }
    }
  ]
});

export default config;
