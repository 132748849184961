export default {
  common: {
    colon: '：',
    close: '닫기',
    jpy: 'JPY',
    error: '에러',
    contact: '문의하기',
    delete: '삭제'
  },
  email: {
    subject: '주문（{{orderId}}）에 대해',
    orderId: '주문번호: {{orderId}}',
    contact: '담당자님, \n<문의내용을 아래에 기입해 주세요. >'
  },
  account: {
    title: '결제수단관리',
    caption: '등록된 신용카드',
    tips: '※카드는 최대 5개까지 추가 가능합니다.',
    deleteCardSuccess: '카드를 삭제했습니다.'
  },
  order: {
    bookingId: '예약번호',
    totalPrice: '총액',
    useAvailableCard: '등록된 카드 사용하기',
    useNewCard: '새로운 카드 사용하기',
    acceptedPayment: '이용가능한 결제수단',
    email: '메일주소',
    deadline: '결제기한',
    pay: '{{amount}} 결제하기',
    error: '구매 처리 중 오류가 발생했습니다! 에러코드：{{code}}',
    secure: '3D Secure',
    secureMessage:
      '당사 서비스를 이용하기 위해서는 <a>본인 인증 서비스 「3D Secure 2.0(EMV 3D Secure)」</a>가 필요합니다.',
    saveCardInfo: '카드 정보 저장하기',
    creditCardVerified: '카드 확인이 완료되었습니다.',
    incompleteOrder: '아직 주문이 완료되지 않았습니다.',
    clickToConfirm: '반드시 아래의 확정 버튼을 눌러주세요.',
    confirmOrder: '주문 확정하기',
    countdown: '({{count}}초)',
    paymentError: '결제 과정에서 오류가 발생했습니다.',
    confirmDeadline: '주문 정보가 정확한지, 유효기간이 맞는지 확인해 주세요.',
    orderSuccess: '주문이 완료되었습니다. \n 이용해 주셔서 감사합니다.',
    contact:
      '문제가 해결되지 않는 경우 \n 「문의하기」를 통해 연락주시기 바랍니다.',
    backShop: '판매 페이지로 돌아가기',
    paymentDetails: '결제 내역',
    paymentLogs: '결제 기록',
    print: '이 페이지 인쇄하기'
  },
  product: {
    name: '종류',
    price: '단가',
    quantity: '수량',
    total: '금액'
  },
  transaction: {
    timestamp: '시간',
    status: '상태',
    message: '상세 정보',
    charge: '지불 방법'
  },
  card: {
    cardNumber: '카드번호',
    cardExpiry: '유효기간',
    cardCvc: '보안 코드',
    cardName: '카드에 표시되는 이름'
  },
  footer: {
    terms: '이용 약관',
    privacy: '개인 정보 보호 정책',
    faq: '자주 묻는 질문'
  },
  error: {
    invalidCardNumber: '유효하지 않은 카드 번호입니다.',
    invalidCardType: '유효하지 않은 카드입니다.',
    invalidCardExpiry: '카드 유효기간이 올바르지 않습니다.',
    pastCardExpiry: '카드 유효기간이 만료되었습니다.',
    invalidCardCvc: '카드 CVC가 유효하지 않습니다.',
    invalidCardName: '이름을 영문으로 입력하세요.'
  }
};
