export default {
  common: {
    colon: '：',
    close: 'Close',
    jpy: 'JPY',
    error: 'ERROR',
    contact: 'Inquiry',
    delete: 'Delete'
  },
  email: {
    subject: 'About the order ({{orderId}})',
    orderId: 'Order number: {{orderId}}',
    contact:
      'Dear Person in Charge, \n<Please provide the details of your inquiry in the space provided below.>>'
  },
  account: {
    title: 'Manage payment methods',
    caption: 'Registered credit cards',
    tips: 'Up to 5 credit cards can be added.',
    deleteCardSuccess: 'The credit card information has been deleted.'
  },
  order: {
    bookingId: 'Booking number',
    totalPrice: 'Total amount',
    useAvailableCard: 'Use a registered credit card',
    useNewCard: 'Use a new credit card',
    acceptedPayment: 'Accepted payments',
    email: 'E-mail address',
    deadline: 'Payment should be made before',
    pay: 'Submit payment {{amount}} ',
    error:
      'Sorry, there was an error that occurred during the purchase process. Error code: {{code}}',
    secure: '3D Secure',
    secureMessage:
      'To use our services, you must enable <a> 3D Secure 2.0 (EMV 3D Secure) personal authentication service on your card</a>.',
    saveCardInfo: 'Save the credit card information',
    creditCardVerified: 'Your credit card has been verified.',
    incompleteOrder: 'Your booking has not been completed yet.',
    clickToConfirm:
      'Please make sure that you press the confirmation button provided below.',
    confirmOrder: 'Confirm your booking',
    countdown: '({{count}} seconds)',
    paymentError:
      'There was an issue that occurred during the processing of your payment.',
    confirmDeadline:
      'Please verify the booking details for accuracy and the expiration date.',
    orderSuccess:
      'Your booking is confirmed. \n Thank you for choosing our service.',
    contact:
      'If the issue cannot be resolved, please contact us via the \n "Contact Us" option.',
    backShop: 'Go back to the product page',
    paymentDetails: 'Payment details',
    paymentLogs: 'Payment history',
    print: 'Print this page'
  },
  product: {
    name: 'Type',
    price: 'Unit price',
    quantity: 'Quantity',
    total: 'Amount'
  },
  transaction: {
    timestamp: 'Time',
    status: 'Status',
    message: 'Detailed information',
    charge: 'Payment'
  },
  card: {
    cardNumber: 'Card number',
    cardExpiry: 'Expiration date',
    cardCvc: 'Security code',
    cardName: 'Card holder name'
  },
  footer: {
    terms: 'Terms of service',
    privacy: 'Privacy policy',
    faq: 'FAQ'
  },
  error: {
    invalidCardNumber: 'Invalid card number.',
    invalidCardType: 'Invalid card type.',
    invalidCardExpiry: 'The expiration date of the card is invalid.',
    pastCardExpiry: 'Your card has expired.',
    invalidCardCvc: 'The security code on the card you provided is invalid.',
    invalidCardName:
      'Please enter your name using standard English alphabet letters in half-width format.'
  }
};
