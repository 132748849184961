import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Modal, Button, ExternalLink } from '@/components';
import { useStore } from '@/stores';
import { ErrorIcon } from '@/assets/icons';
import styles from './error.module.css';

const ErrorMsg: React.FC = observer(() => {
  const { t } = useTranslation();
  // const { orderId } = useParams();
  const [visible, setVisible] = useState(false);
  const { errorMsg } = useStore();

  useEffect(() => {
    if (errorMsg.currentMessage) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [errorMsg.currentMessage]);

  const close = useCallback(() => {
    setVisible(false);
    // after render clear message
    window.setTimeout(() => {
      errorMsg.clearMessage();
    }, 300);
  }, [errorMsg]);

  // const mail = useMemo(() => {
  //   return encodeURI(
  //     `mailto:helpcenter@linktivity.co.jp?subject=${t('email.subject', {
  //       orderId
  //     })}&body=${t('email.orderId', {
  //       orderId
  //     })}\n${errorMsg.currentMessage}\n\n${t('email.contact')}`
  //   );
  // }, [t, orderId, errorMsg.currentMessage]);

  return (
    <Modal visible={visible} onClose={close}>
      <div className={styles.error}>
        <div className={styles.errorHeader}>
          <ErrorIcon className={styles.errorIcon} />
          <span className={styles.errorTitle}>{t('common.error')}</span>
        </div>
        <p className={styles.errorMsg}>{errorMsg.currentMessage}</p>
      </div>
      <div className={styles.errorAction}>
        {/* <ExternalLink href={mail} className={styles.errorActionButton}>
          {t('common.contact')}
          <Email className={styles.email} />
        </ExternalLink> */}
        <ExternalLink
          className={styles.errorActionButton}
          href={`${location.origin}/faq`}
        >
          {t('footer.faq')}
        </ExternalLink>
        <Button color="primary" className={styles.errorClose} onClick={close}>
          {t('common.close')}
        </Button>
      </div>
    </Modal>
  );
});

export default ErrorMsg;
